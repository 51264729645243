import { CohortEventInstanceStatus } from "@generated/graphql";
import {
  COHORT_SESSION_BUFFER_MS,
  localizedTime,
  printDuration,
} from "@utils/dateTime";
import clsx from "clsx";
import { Icon } from "components/shared";
import {
  CalendarEventCohortInstanceInfo,
  DEFAULT_EVENT_COLOR,
} from "components/weekCalendar";
import { getDateData } from "components/weekCalendar/components/CalendarEvents/utils";
import { useAuth } from "contexts/AuthProvider";
import formatInTimeZone from "date-fns-tz/formatInTimeZone";
import { CalendarEventCancelButton } from "../../CalendarEventCancelButton";
import { UndoCalendarEventCancellationButton } from "../../UndoCalendarEventCancellationButton";
import { CalendarEventPopoverProps as Props } from "../types";

export function CalendarEventCohortInstancePopoverBody({
  eventInfo,
  localizedWeekdays,
  locale,
  mode24Hour,
  viewingTimeZone,
  refetch,
}: Props<CalendarEventCohortInstanceInfo>) {
  const now = new Date();
  const { isAdmin } = useAuth();
  const startInfo = getDateData(eventInfo, viewingTimeZone);
  const endInfo = getDateData(eventInfo, viewingTimeZone, true);
  const eventDateFormat = new Intl.DateTimeFormat(locale, {
    dateStyle: "medium",
    timeZone: "UTC",
  });

  return (
    <div
      className={clsx(
        "relative flex flex-row bg-white gap-3 px-2 py-3 lg:p-3 w-[350px]",
        "rounded-lg shadow-lg outline-1 outline-black/10"
      )}
    >
      {/* Left section */}
      <div className="flex flex-col place-content-between w-auto my-1">
        <div className="flex flex-col items-center gap-1">
          <p
            className={clsx(
              "font-semibold text-sm whitespace-nowrap",
              eventInfo.eventColor?.text
            )}
          >
            {localizedTime(startInfo.time, mode24Hour, locale)}
          </p>
          <p
            className={clsx(
              "font-normal text-sm whitespace-nowrap",
              eventInfo.eventColor?.text
            )}
          >
            {localizedTime(endInfo.time, mode24Hour, locale)}
          </p>
          <p
            className={clsx(
              "font-normal text-xs whitespace-nowrap capitalize",
              eventInfo.eventColor?.text
            )}
          >
            {localizedWeekdays[startInfo.weekdayNumber].longWeekday}
          </p>
        </div>
        <div className="flex flex-col gap-y-2">
          <p
            className={clsx(
              "font-normal text-xs whitespace-nowrap italic",
              eventInfo.eventColor?.text
            )}
          >
            {printDuration(eventInfo.durationMinutes, 60)}
          </p>
          {+eventInfo.startDateTime > +now + COHORT_SESSION_BUFFER_MS &&
          isAdmin &&
          eventInfo.cohortEventId !== null ? (
            <div className="-ml-0.5">
              {eventInfo.status !== CohortEventInstanceStatus.Cancelled ? (
                <CalendarEventCancelButton
                  cohortEventId={eventInfo.cohortEventId}
                  isoStartFloatingDateTime={eventInfo.isoStartFloatingDateTime}
                  refetch={refetch}
                />
              ) : (
                <UndoCalendarEventCancellationButton
                  cohortEventId={eventInfo.cohortEventId}
                  isoStartFloatingDateTime={eventInfo.isoStartFloatingDateTime}
                  refetch={refetch}
                />
              )}
            </div>
          ) : null}
        </div>
      </div>

      {/* Vertical line */}
      <div
        className={clsx(
          "shrink-0 w-0.5 h-auto rounded-xs",
          eventInfo.eventColor?.accent
        )}
      />

      {/* Right section */}
      <div className="grow flex flex-col gap-2 my-1">
        <div className="flex flex-col gap-1">
          <div
            className={clsx(
              "flex flex-row justify-between gap-x-2 font-semibold text-sm leading-tight",
              eventInfo.eventColor?.text
            )}
          >
            <span>{eventInfo.title || "Untitled Event"}</span>
            {eventInfo.charmIcon && (
              <Icon
                icon={eventInfo.charmIcon}
                className="w-6 h-6 shrink-0"
                color={eventInfo.eventColor?.text}
              />
            )}
          </div>
          <p className="font-normal text-xs text-gray-500 tabular-nums">
            {eventDateFormat.format(eventInfo.cohortStartDate)} -{" "}
            {eventDateFormat.format(eventInfo.cohortEndDate)}
          </p>
          {eventInfo.details && (
            <p className="font-normal text-xs leading-snug text-gray-500">
              {eventInfo.details}
            </p>
          )}
        </div>
        {eventInfo.content?.({
          eventColor: eventInfo.eventColor ?? DEFAULT_EVENT_COLOR,
        })}

        {viewingTimeZone !== eventInfo.timeZone && (
          <p className="font-normal text-xs leading-none text-gray-400 italic">
            {localizedTime(
              formatInTimeZone(
                eventInfo.startDateTime,
                eventInfo.timeZone,
                "HH:mm"
              ),
              mode24Hour,
              locale
            )}{" "}
            Local Start Time
            <br />({eventInfo.timeZone.replace("_", " ")})
          </p>
        )}
      </div>
    </div>
  );
}
