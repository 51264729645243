import { gql, useLazyQuery } from "@apollo/client";
import {
  GetCohortMeetingLinkQuery,
  GetCohortMeetingLinkQueryVariables,
} from "@generated/graphql";
import { Button, Spinner } from "@shared";
import { openInNewTab } from "@utils/browser";
import clsx from "clsx";
import React, { useEffect } from "react";
import { triggerErrorToast } from "./Toast";

const GET_COHORT_MEETING_LINK = gql`
  query GetCohortMeetingLink($id: ID!) {
    cohort(id: $id) {
      hostMeetingLink
      studentMeetingLink
    }
  }
`;

type Props = {
  hide?: boolean;
  isHost: boolean;
  cohortId?: string;
  className?: string;
  disabled?: boolean;
  children?: React.ReactNode;
  loadingCallback?: (loading: boolean) => void;
};

export const JoinMeetingButton = ({
  isHost,
  children,
  disabled,
  cohortId,
  className,
  hide = false,
  loadingCallback,
}: Props) => {
  const openOrErrorToast = (link?: string | null, errorMsg?: string) => {
    if (link) {
      openInNewTab(link);
    } else {
      triggerErrorToast({
        log: errorMsg,
        message: "Join Failure",
        sub: "No meeting link could be found or generated.",
      });
    }
  };

  const [getCohortMeetingLink, { loading }] = useLazyQuery<
    GetCohortMeetingLinkQuery,
    GetCohortMeetingLinkQueryVariables
  >(GET_COHORT_MEETING_LINK, {
    fetchPolicy: "no-cache",
    onError: (error) => openOrErrorToast(null, error.message),
    onCompleted: ({ cohort }) => {
      const { hostMeetingLink, studentMeetingLink } = cohort || {};
      openOrErrorToast(isHost ? hostMeetingLink : studentMeetingLink);
    },
  });

  useEffect(() => {
    loadingCallback?.(loading);
  }, [loading, loadingCallback]);

  return !cohortId || hide ? null : (
    <div
      className={className}
      onClick={() => {
        if (disabled) return;
        getCohortMeetingLink({ variables: { id: cohortId } });
      }}
    >
      {children ?? (
        <Button height="xs" className="w-fit">
          {loading && <Spinner size={3} color="text-white" />}
          <div className={clsx(loading ? "opacity-0" : "opacity-1")}>
            Join Meeting
          </div>
        </Button>
      )}
    </div>
  );
};
