import { CohortEventInstanceStatus } from "@generated/graphql";
import { localizedTime, printDuration } from "@utils/dateTime";
import { getSubjectText } from "@utils/labels";
import { Routes } from "@utils/routes";
import { getScrollbarStyle } from "@utils/styleStrings";
import clsx from "clsx";
import { Icon, Link } from "components/shared";
import { CalendarEventEngagementAggregateInfo } from "components/weekCalendar";
import { getDateData } from "components/weekCalendar/components/CalendarEvents/utils";
import { useAuth } from "contexts/AuthProvider";
import formatInTimeZone from "date-fns-tz/formatInTimeZone";
import { CalendarEventPopoverProps as Props } from "../types";

export function CalendarEventEngagementAggregateCalendarEventPopoverBody({
  eventInfo,
  localizedWeekdays,
  locale,
  mode24Hour,
  viewingTimeZone,
}: Props<CalendarEventEngagementAggregateInfo>) {
  const { isAdmin } = useAuth();
  const startInfo = getDateData(eventInfo, viewingTimeZone);
  const endInfo = getDateData(eventInfo, viewingTimeZone, true);
  const eventDateFormat = new Intl.DateTimeFormat(locale, {
    dateStyle: "medium",
    timeZone: "UTC",
  });

  return (
    <div
      className={clsx(
        "relative flex flex-row bg-white gap-3 px-2 py-3 lg:p-3 w-[350px]",
        "rounded-lg shadow-lg outline-1 outline-black/10"
      )}
    >
      {/* Left section */}
      <div className="flex flex-col place-content-between w-auto my-1">
        <div className="flex flex-col items-center gap-1">
          <p
            className={clsx(
              "font-semibold text-sm whitespace-nowrap",
              eventInfo.eventColor?.text
            )}
          >
            {localizedTime(startInfo.time, mode24Hour, locale)}
          </p>
          <p
            className={clsx(
              "font-normal text-sm whitespace-nowrap",
              eventInfo.eventColor?.text
            )}
          >
            {localizedTime(endInfo.time, mode24Hour, locale)}
          </p>
          <p
            className={clsx(
              "font-normal text-xs whitespace-nowrap capitalize",
              eventInfo.eventColor?.text
            )}
          >
            {localizedWeekdays[startInfo.weekdayNumber].longWeekday}
          </p>
        </div>
        <div className="flex flex-col gap-y-2">
          <p
            className={clsx(
              "font-normal text-xs whitespace-nowrap italic",
              eventInfo.eventColor?.text
            )}
          >
            {printDuration(eventInfo.durationMinutes, 60)}
          </p>
        </div>
      </div>

      {/* Vertical line */}
      <div
        className={clsx(
          "shrink-0 w-0.5 h-auto rounded-xs",
          eventInfo.eventColor?.accent
        )}
      />

      {/* Right section */}
      <div className="grow flex flex-col gap-2 my-1">
        <div className="flex flex-col gap-1">
          <div className="flex flex-row justify-between gap-x-2 font-semibold text-sm leading-tight">
            <Link
              className={eventInfo.eventColor?.text}
              disabled={!isAdmin}
              route={Routes.engagement.details}
              routeProps={[eventInfo.engagementId]}
            >
              {eventInfo.title || "Untitled Event"}
            </Link>
            <Icon
              icon="engagement"
              size={6}
              color={eventInfo.eventColor?.text}
            />
          </div>
          <p className="font-normal text-xs text-gray-500 tabular-nums">
            {eventDateFormat.format(eventInfo.engagementStartDate)} -{" "}
            {eventDateFormat.format(eventInfo.engagementEndDate)}
          </p>
        </div>

        <div
          className={clsx(
            "flex flex-col gap-2 max-h-48 overflow-auto",
            getScrollbarStyle("gray")
          )}
        >
          {eventInfo.events.map((event) => (
            <Link
              key={event.cohortId}
              className="flex flex-col gap-y-0 odd:bg-white even:bg-slate-100 px-1.5 py-1 rounded-sm"
              disabled={!isAdmin}
              route={Routes.cohort.details}
              routeProps={[event.cohortId]}
            >
              <div className="flex flex-row gap-x-1 justify-between">
                <div className="flex flex-col gap-y-0">
                  <p
                    className={clsx(
                      "text-sm text-wrap",
                      event.status === CohortEventInstanceStatus.Cancelled
                        ? "line-through text-slate-700"
                        : eventInfo.eventColor?.text ?? "text-slate-700"
                    )}
                  >
                    {event.cohortName}
                  </p>
                  <p className="font-light text-xs text-wrap text-slate-500">
                    {getSubjectText(
                      event.cohortSubject,
                      "long",
                      event.cohortSubSubject
                    )}
                  </p>
                </div>
                {isAdmin && (
                  <p className="font-mono text-slate-500 text-xs text-nowrap tabular-nums">
                    ID: {event.cohortId}
                  </p>
                )}
              </div>
            </Link>
          ))}
        </div>

        {viewingTimeZone !== eventInfo.timeZone && (
          <p className="font-normal text-xs leading-none text-gray-400 italic">
            {localizedTime(
              formatInTimeZone(
                eventInfo.startDateTime,
                eventInfo.timeZone,
                "HH:mm"
              ),
              mode24Hour,
              locale
            )}{" "}
            Local Start Time
            <br />({eventInfo.timeZone.replace("_", " ")})
          </p>
        )}
      </div>
    </div>
  );
}
