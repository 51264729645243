import { HolidayType } from "@generated/graphql";
import { ISODate, WeekdayNumber } from "@utils/dateTime";
import clsx from "clsx";
import { Icon, Tooltip } from "components/shared";
import { useAuth } from "contexts/AuthProvider";
import addWeeks from "date-fns/addWeeks";
import pluralize from "pluralize";
import { CalendarNavData } from "../types";
import { CalendarEventNavCancelButton } from "./CalendarEventNavCancelButton";
import { HolidaysIndicator } from "./Header/components/HolidaysIndicator";

type Props = {
  className: string;
  currentViewerISODate: ISODate;
  navData: CalendarNavData[];
  focusedWeekday: WeekdayNumber;
  viewDate: Date;
  setViewDate: (date: Date) => void;
  refetch?: () => void;
  hideEventCount?: boolean;
};
export const MobileNav = ({
  className,
  currentViewerISODate,
  navData,
  focusedWeekday,
  viewDate,
  setViewDate,
  refetch,
  hideEventCount = false,
}: Props) => {
  const { isAdmin } = useAuth();

  const weekButton = (back: boolean) => (
    <button
      type="button"
      autoFocus={false}
      className={clsx(
        "hidden lg:flex absolute flex-center z-20 h-[calc(100%-16px)] top-2 w-8 rounded-md",
        "bg-slate-300/10 px-1 border border-slate-300/40 group",
        "hover:bg-slate-200/50 hover:border-slate-400/50",
        back ? "left-0" : "right-0"
      )}
      onClick={() => setViewDate(addWeeks(viewDate, back ? -1 : 1))}
    >
      <span className="sr-only">{back ? "previous" : "next"} week</span>
      <Icon
        size={5}
        icon={back ? "chevronLeft" : "chevronRight"}
        color="text-slate-400 group-hover:text-slate-500"
      />
    </button>
  );

  return (
    <div className="flex flex-row gap-x-0 flex-nowrap text-gray">
      {weekButton(true)}
      <div
        className={clsx(
          "grid-cols-7 text-sm leading-6 text-gray-900 flex-1 mx-9",
          className
        )}
      >
        {navData.map(({ localizedWeekday, eventInfos, navHolidays }, i) => {
          const { isoDate, longWeekday, shortWeekday, day, date } =
            localizedWeekday;
          const focusedDay = focusedWeekday === i;
          const currentDay = currentViewerISODate === isoDate;

          return (
            <div
              key={longWeekday}
              className="flex flex-col items-center py-2 cursor-pointer"
              onClick={() => setViewDate(date)}
            >
              <div
                className={clsx(
                  "flex flex-col py-1 h-full w-full rounded-lg border-2 items-center justify-start font-semibold capitalize border-transparent",
                  currentDay && "bg-red-500/90 border-red-700/70! text-white",
                  focusedDay && "bg-blue-600/90 border-blue-700/70! text-white",
                  currentDay &&
                    focusedDay &&
                    "text-white bg-[linear-gradient(100deg,_#f56565,_#3182ce)]"
                )}
              >
                <span>{shortWeekday}</span>
                <span>{day}</span>
                {!hideEventCount && (
                  <span
                    className={clsx(
                      "text-gray-500 font-normal text-2xs",
                      currentDay && focusedDay && "text-white"
                    )}
                  >
                    {pluralize("event", eventInfos.length, true)}
                  </span>
                )}

                <div className="flex flex-row gap-x-1">
                  {isAdmin &&
                    currentViewerISODate <= isoDate &&
                    eventInfos.length !== 0 && (
                      <CalendarEventNavCancelButton
                        currentDay={currentDay}
                        eventInfos={eventInfos}
                        localizedWeekday={localizedWeekday}
                        refetch={refetch}
                        mobileMode
                      />
                    )}
                  {navHolidays.length > 0 && (
                    <Tooltip
                      content={pluralize(
                        navHolidays.every(
                          ({ type }) => type === HolidayType.Usa
                        )
                          ? "US Holiday"
                          : "No tutoring day",
                        navHolidays.length,
                        true
                      )}
                      tooltipProps={{ place: "bottom" }}
                    >
                      <Icon icon="palmTree" color="text-green-600" size={4} />
                    </Tooltip>
                  )}
                </div>
              </div>
            </div>
          );
        })}

        {!!navData[focusedWeekday].navHolidays.length && (
          <div className="col-span-7 w-full pb-1 pr-1 pl-14">
            <HolidaysIndicator
              navHolidays={navData[focusedWeekday].navHolidays}
            />
          </div>
        )}
      </div>
      {weekButton(false)}
    </div>
  );
};
