import { gql, useMutation } from "@apollo/client";
import { Placement } from "@floating-ui/react-dom-interactions";
import {
  BuildICalendarInput,
  BuildICalendarMutation,
  BuildICalendarResult,
} from "@generated/graphql";
import { Popover, Transition } from "@headlessui/react";
import { getOrigin } from "@utils/browser";
import { Routes } from "@utils/routes";
import clsx from "clsx";
import {
  Button,
  ButtonHeight,
  CopyToClipboardInputButtonCombo,
  Spinner,
  getPopoverPanelOrientationRules,
  triggerErrorToast,
  triggerSuccessToast,
} from "components/shared";
import {
  OpenOrientation,
  getOpenOrientation,
} from "components/shared/Buttons/PopoverButton";
import { Chevron } from "components/shared/Chevron";
import isEqual from "lodash/isEqual";
import { FC, Fragment, useEffect, useState } from "react";
import { usePopper } from "react-popper";
import { useDebounce } from "use-debounce";
import {
  CalendarService,
  buildCalendarServices,
  buildICalUrl,
} from "./iCalendarUtils";

const BUILD_ICALENDER_MUTATION = gql`
  mutation BuildICalendar($input: BuildICalendarInput!) {
    buildICalendar(input: $input) {
      referenceId
      newCalendar
    }
  }
`;

/**
 * If you want to build a component that can edit the calendar it needs these
 * props.
 */
export type InputEditorProps = {
  debouncePending: boolean;
  input: BuildICalendarInput;
  onUpdateInput: (input: BuildICalendarInput) => void;
  calendarNameFunc?: (input: BuildICalendarInput) => string;
};

type OnUpdate = (
  referenceId: BuildICalendarResult["referenceId"] | null
) => void;

type Props = {
  buttonLabel?: string;
  buttonClassName?: string;
  buttonHeight?: ButtonHeight;
  openOrientation?: OpenOrientation;
  inputEditor?: FC<InputEditorProps>;
  initialBuildICalendarInput: BuildICalendarInput;
  calendarNameFunc?: (input: BuildICalendarInput) => string;
};

export function ICalendarGeneratorPopover({
  buttonClassName,
  buttonHeight = "sm",
  inputEditor: InputEditor,
  initialBuildICalendarInput,
  openOrientation = "BOTTOM-END",
  buttonLabel = "Add to Calendar",
  calendarNameFunc,
}: Props) {
  const [refElement, setRefElement] = useState<HTMLElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLElement | null>(null);
  const { styles, attributes, update } = usePopper(refElement, popperElement, {
    placement:
      (openOrientation.toLocaleLowerCase() as Placement) ?? "bottom-end",
  });
  const [input, setInput] = useState<BuildICalendarInput>(
    initializeInput(initialBuildICalendarInput, calendarNameFunc)
  );
  const [lastInput, setLastInput] = useState<BuildICalendarInput | null>(null);
  const [referenceId, setReferenceId] = useState<
    BuildICalendarResult["referenceId"] | null
  >(null);

  const [debouncedInput, { isPending: isPendingFn }] = useDebounce(input, 1500);
  const isPending = isPendingFn();

  // Make sure the input states are reset when loading different calendars.
  useEffect(() => {
    setInput(initializeInput(initialBuildICalendarInput, calendarNameFunc));
    setLastInput(null);
  }, [initialBuildICalendarInput, calendarNameFunc]);

  const onUpdate: OnUpdate = (referenceId: string | null) => {
    setReferenceId(referenceId);
    setLastInput(input);
  };

  const { orientationClassNames, chevronDirection } =
    getPopoverPanelOrientationRules(openOrientation);

  const iCalUrl = referenceId ? buildICalUrl(getOrigin(), referenceId) : null;
  const calendarServices =
    iCalUrl != null ? buildCalendarServices(iCalUrl, "w-8 h-8") : [];

  useEffect(() => {
    update?.();
  }, [update]);

  return (
    <Popover ref={setRefElement}>
      <Popover.Button as="div">
        {({ open }) => (
          <Button
            theme="tertiary"
            disabled={isPending}
            height={buttonHeight}
            className={clsx(buttonClassName)}
          >
            <div className="flex flex-center relative">
              <span className="opacity-0 relative">{buttonLabel}</span>
              <span
                className={clsx(
                  "absolute leading-4",
                  isPending ? "text-gray-700/0" : "text-gray-700"
                )}
              >
                {buttonLabel}
              </span>
              {isPending && (
                <div className="absolute">
                  <Spinner color="text-blue-600" />
                </div>
              )}
            </div>
            <Chevron
              direction={getOpenOrientation(chevronDirection, open)}
              size={5}
              color="text-slate-700"
              className="transition duration-150 ease-in-out ml-1 mb-[-1px]"
            />
          </Button>
        )}
      </Popover.Button>

      <Popover.Panel
        className={clsx(
          orientationClassNames,
          "absolute z-100 w-screen max-w-xs transform px-4 sm:px-0"
        )}
      >
        {({ close, open }) => (
          <div
            style={styles.popper}
            {...attributes.popper}
            ref={setPopperElement}
            className="mt-2"
          >
            <Transition
              show={open}
              as={Fragment}
              enter="transition ease-out duration-400"
              leave="transition ease-in duration-400"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black/5 min-w-[300px]">
                <div className="relative flex flex-col gap-2 pt-2 bg-white">
                  {iCalUrl ? (
                    <>
                      <div className="flex flex-col gap-px px-1">
                        {calendarServices.map((service) => (
                          <ServiceButton key={service.platform} {...service} />
                        ))}
                      </div>
                      <div className="bg-blue-50 p-4">
                        <div className="flow-root rounded-md transition duration-150 ease-in-out">
                          <div className="flex flex-col gap-3 mtx-3">
                            {InputEditor && (
                              <InputEditor
                                calendarNameFunc={calendarNameFunc}
                                debouncePending={isPending}
                                input={input}
                                onUpdateInput={setInput}
                              />
                            )}
                            <CopyToClipboardInputButtonCombo
                              copyValue={
                                origin +
                                Routes.iCalendarImportPage.href(
                                  referenceId ?? ""
                                )
                              }
                              label="Share Link"
                              buttonLabel=""
                              disabled={isPending}
                              onClickCopy={close}
                              showShareLinkRoute={Routes.iCalendarImportPage.href(
                                referenceId ?? ""
                              )}
                            />
                          </div>
                        </div>
                      </div>
                    </>
                  ) : (
                    <div className="flex flex-center min-h-[400px]">
                      <Spinner color="text-blue-800" />
                    </div>
                  )}
                </div>

                {open && !isEqual(debouncedInput, lastInput) && (
                  <Builder input={input} onUpdate={onUpdate} />
                )}
              </div>
            </Transition>
          </div>
        )}
      </Popover.Panel>
    </Popover>
  );
}

type BuilderProps = {
  input: BuildICalendarInput;
  onUpdate: OnUpdate;
};

/**
 * Running a mutation on mount is what I'd call "off-label" use. Apollo's
 * useMutation hook is designed to be triggered by a user action. To trigger
 * when a component is mounted requires careful use of a useEffect hook. So, by
 * mounting this component at the right time we can run a mutation like a query,
 * and if you control when the component is mounted you can also prevent repeated
 * mutations.
 */
const Builder = ({ input, onUpdate }: BuilderProps) => {
  const [buildICalendar] = useMutation<BuildICalendarMutation>(
    BUILD_ICALENDER_MUTATION,
    {
      onError: (error) => {
        onUpdate(null);
        triggerErrorToast({
          message: "Looks like something went wrong.",
          sub: "We weren't able to generate your calendar.",
          log: error,
        });
      },
      onCompleted: (data) => {
        if (data.buildICalendar.newCalendar) {
          triggerSuccessToast({
            message: "New calendar successfully generated.",
          });
        }
        onUpdate(data.buildICalendar.referenceId);
      },
    }
  );

  useEffect(() => {
    onUpdate(null);

    // Don't await. Callbacks handle the results.
    buildICalendar({
      variables: { input },
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [input]);

  return null;
};

const ServiceButton = ({ icon, url, title, subtitle }: CalendarService) => (
  <a
    href={url}
    className="flex items-center rounded-lg transition p-2 duration-150 ease-in-out hover:bg-gray-100 focus:outline-hidden focus-visible:ring-3 focus-visible:ring-blue-500/50"
    {...(url.includes("webcal://")
      ? {}
      : { target: "_blank", rel: "noreferrer" })}
  >
    <div className="flex h-10 w-10 shrink-0 flex-center text-white sm:h-12 sm:w-12">
      {icon}
    </div>
    <div className="ml-4">
      <p className="text-sm font-medium text-gray-900">{title}</p>
      <p className="text-sm text-gray-500">{subtitle}</p>
    </div>
  </a>
);

const initializeInput = (
  input: BuildICalendarInput,
  calendarNameFunc?: (input: BuildICalendarInput) => string
) => ({
  ...input,
  name: calendarNameFunc && calendarNameFunc(input),
});
