import { gql } from "@apollo/client";
import {
  AccountRole,
  AccountStatus,
  CalendarEventCohortDetails_CohortFragment,
  VideoProvider,
} from "@generated/graphql";
import { Icon } from "@shared";
import { JoinMeetingButton } from "@shared/JoinMeetingButton";
import { getAccountRoleText, getCohortAssignmentRoleText } from "@utils/labels";
import clsx from "clsx";
import { DEFAULT_EVENT_COLOR } from "components/weekCalendar";
import { ContentProps, EventColor } from "../types";

CalendarEventCohortDetails.fragments = {
  cohort: gql`
    fragment CalendarEventCohortDetails_Cohort on Cohort {
      id
      engagement {
        videoProvider
      }
      staffAssignments {
        user {
          id
          accountRole
          fullName
          accountStatus
        }
        cohortSubject
        cohortAssignmentRole
      }
    }
  `,
};

type Props = ContentProps & {
  cohort: CalendarEventCohortDetails_CohortFragment;
  filteredStaffAssignments: CalendarEventCohortDetails_CohortFragment["staffAssignments"];
};

export function CalendarEventCohortDetails({
  filteredStaffAssignments,
  cohort: { id, engagement },
  eventColor = DEFAULT_EVENT_COLOR,
}: Props) {
  const isZoomProvider = engagement.videoProvider === VideoProvider.Zoom;

  const getJoinButton = (eventColor: EventColor, isHostLink: boolean) => (
    <div
      className={clsx(
        "flex justify-start whitespace-nowrap rounded-l-md text-sm",
        "focus:outline-hidden focus:ring-1 focus:ring-blue-500",
        "focus:border-blue-500 p-2 focus:z-10 z-10",
        "relative inline-flex items-center w-full font-medium",
        `${eventColor.text} ${eventColor.bg} ${eventColor.bgHover}`
      )}
    >
      <Icon icon="video" size={5} className="mr-1" />
      {`Join Meeting (As ${isHostLink ? "Host" : "Student"})`}
    </div>
  );

  return (
    <div className="flex flex-col gap-2">
      {filteredStaffAssignments.length > 0 ? (
        filteredStaffAssignments.map((staffAssignment) => (
          <div key={staffAssignment.user.id} className="flex flex-col">
            <p className={`text-sm text-semibold ${eventColor?.text}`}>
              {staffAssignment.user.fullName}
              {staffAssignment.user.accountRole !==
                AccountRole.TutorTeacher && ( // Provide visibility for MTs and Admins
                <span className="font-normal italic">
                  {` (${getAccountRoleText(staffAssignment.user.accountRole)})`}
                </span>
              )}
              {staffAssignment.user.accountStatus ===
                AccountStatus.Inactive && (
                <span className="text-red-500 font-semibold"> (Inactive)</span>
              )}
            </p>
            <p className="text-xs italic text-gray-400">
              <span>{getCohortAssignmentRoleText("long")}</span>
            </p>
          </div>
        ))
      ) : (
        <p className="text-sm text-semibold">
          No staff has been assigned to this subject.
        </p>
      )}
      <div className="flex flex-col w-fit gap-y-1">
        <JoinMeetingButton isHost={true} cohortId={id}>
          {getJoinButton(eventColor, true)}
        </JoinMeetingButton>

        <JoinMeetingButton hide={!isZoomProvider} isHost={false} cohortId={id}>
          {getJoinButton(eventColor, false)}
        </JoinMeetingButton>
      </div>
    </div>
  );
}
