import { gql } from "@apollo/client";
import { Spinner } from "@shared";
import {
  DAY_MIN,
  IANAtzName,
  LocalizedWeekday,
  getDateInfo,
} from "@utils/dateTime";
import { getScrollbarStyle } from "@utils/styleStrings";
import clsx from "clsx";
import utcToZonedTime from "date-fns-tz/utcToZonedTime";
import { useEffect, useRef } from "react";
import { CalendarEvents } from "./components/CalendarEvents";
import { DesktopNav } from "./components/DesktopNav";
import { HourLines } from "./components/HourLines";
import { MobileNav } from "./components/MobileNav";
import { TimeLine } from "./components/Timeline";
import { GRID_RESOLUTION_MIN, RULE_RESOLUTION_MIN } from "./constants";
import {
  CalendarDisplayMode,
  CalendarEventInfo,
  CalendarNavData,
} from "./types";
import { getDisplayRules } from "./utils";

WeekCalendar.fragments = {
  holiday: gql`
    fragment WeekCalendar_Holiday on Holiday {
      id
      type
      name
      endDate
      startDate
    }
  `,
};

type Props = {
  viewDate: Date;
  locale: string;
  className?: string;
  mode24Hour?: boolean;
  hideEventCount?: boolean;
  navData: CalendarNavData[];
  viewingTimeZone: IANAtzName;
  displayMode?: CalendarDisplayMode;
  localizedWeekdays: LocalizedWeekday[];
  eventInfosForDisplay: CalendarEventInfo[];
  refetch?: () => void;
  setViewDate: (date: Date) => void;
  loading: boolean;
};

export function WeekCalendar({
  locale,
  navData,
  viewDate,
  className,
  viewingTimeZone,
  localizedWeekdays,
  mode24Hour = false,
  eventInfosForDisplay,
  hideEventCount = false,
  displayMode = CalendarDisplayMode.Responsive,
  refetch,
  setViewDate,
  loading,
}: Props) {
  // Get current dateTime of viewingTimezone so as to simulate different time zones
  const currentViewerDateTime = utcToZonedTime(new Date(), viewingTimeZone);
  const currentDateInfo = getDateInfo(currentViewerDateTime);
  const targetDateInfo = getDateInfo(viewDate);

  const container = useRef<HTMLDivElement>(null);
  const containerNav = useRef<HTMLDivElement>(null);
  const containerOffset = useRef<HTMLDivElement>(null);

  useEffect(() => {
    // Set the container scroll position based on the current viewing tz hour.
    if (
      container?.current != null &&
      containerNav?.current != null &&
      containerOffset?.current != null
    ) {
      // Cannot use currentViewerDateTime above without causing additional calls.
      const currentHourMinute =
        utcToZonedTime(new Date(), viewingTimeZone).getHours() * 60;

      // Calculate the new scroll position as multiple of the height by the
      // amount of the day has elapsed.
      const newScrollPosition =
        ((container.current.scrollHeight -
          containerNav.current.offsetHeight -
          containerOffset.current.offsetHeight) *
          currentHourMinute) /
        1440;

      // Give the component time to mount.
      setTimeout(() => {
        container?.current?.scrollTo({
          top: newScrollPosition,
          behavior: "smooth",
        });
      }, 100);
    }
  }, [viewingTimeZone]);

  const displayRules = getDisplayRules(displayMode);

  return (
    <>
      <div className="relative flex w-full h-0">
        {loading && (
          <div className="absolute flex w-full z-50 justify-center top-48">
            <div className="bg-blue-200/20 rounded-full w-20 h-20 blur-sm"></div>
            <Spinner
              className="absolute w-full h-full flex justify-center p-4"
              color="text-blue-400"
            />
          </div>
        )}
        <div
          ref={containerNav}
          className={clsx(
            "absolute w-full z-40 flex-none bg-white border border-l-0 border-r-0 border-gray-200 lg:pr-1",
            className
          )}
        >
          <MobileNav
            navData={navData}
            viewDate={viewDate}
            hideEventCount={hideEventCount}
            className={displayRules.mobileNav}
            currentViewerISODate={currentDateInfo.isoDate}
            focusedWeekday={targetDateInfo.weekdayNumber}
            refetch={refetch}
            setViewDate={(date: Date) => setViewDate(date)}
          />

          <DesktopNav
            navData={navData}
            hideEventCount={hideEventCount}
            className={displayRules.desktopNav}
            currentViewerISODate={currentDateInfo.isoDate}
            refetch={refetch}
          />
        </div>
      </div>

      <div
        style={{
          height: `${containerNav?.current?.clientHeight ?? 0}px`,
        }}
      ></div>

      <div
        ref={container}
        className={clsx(
          "h-full flex flex-auto flex-col isolate overflow-y-auto overflow-x-clip bg-white",
          getScrollbarStyle("gray")
        )}
      >
        <div className="relative flex max-w-full flex-none flex-col">
          {/* Events Grid Section */}
          <div className="flex flex-auto">
            <div className="sticky left-0 z-10 w-14 flex-none bg-white ring-1 ring-gray-100" />
            <div className="grid flex-auto grid-cols-1 grid-rows-1">
              {/* Horizontal lines */}
              <div
                className="col-start-1 col-end-2 row-start-1 grid divide-y divide-gray-100"
                style={{
                  gridTemplateRows: `repeat(${Math.floor(
                    DAY_MIN / RULE_RESOLUTION_MIN
                  )}, minmax(3.5rem, 1fr))`,
                }}
              >
                <div ref={containerOffset} className="row-end-1 h-7" />
                <HourLines locale={locale} mode24Hour={mode24Hour} />
              </div>
              {/* Vertical lines */}
              <div
                className={clsx(
                  "col-start-1 col-end-2 row-start-1 grid-cols-7 divide-x divide-gray-100",
                  displayRules.verticalLines
                )}
              >
                <div className="col-start-1 row-span-full" />
                <div className="col-start-2 row-span-full" />
                <div className="col-start-3 row-span-full" />
                <div className="col-start-4 row-span-full" />
                <div className="col-start-5 row-span-full" />
                <div className="col-start-6 row-span-full" />
                <div className="col-start-7 row-span-full" />
                <div className="col-start-8 row-span-full" />
              </div>

              <ol
                className={clsx(
                  "grid relative col-start-1 col-end-2 row-start-1",
                  displayRules.orderedList
                )}
                style={{
                  gridTemplateRows: `1.75rem repeat(${Math.floor(
                    DAY_MIN / GRID_RESOLUTION_MIN
                  )}, minmax(0, 1fr)) auto`,
                }}
              >
                <TimeLine
                  localizedWeekdays={localizedWeekdays}
                  focusedWeekday={targetDateInfo.weekdayNumber}
                  viewingTimeZone={viewingTimeZone}
                />

                <CalendarEvents
                  calendarEventClassName={displayRules.event}
                  localizedWeekdays={localizedWeekdays}
                  focusedWeekday={targetDateInfo.weekdayNumber}
                  eventInfos={eventInfosForDisplay}
                  locale={locale}
                  viewingTimeZone={viewingTimeZone}
                  mode24Hour={mode24Hour}
                  refetch={refetch}
                />
              </ol>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
