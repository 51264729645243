import {
  getDateInfo,
  IANAtzName,
  LocalizedWeekday,
  WeekdayNumber,
} from "@utils/dateTime";
import clsx from "clsx";
import utcToZonedTime from "date-fns-tz/utcToZonedTime";
import { useEffect, useState } from "react";
import { GRID_RESOLUTION_MIN } from "../constants";
import { WEEK_COL_START_CLASSES } from "./CalendarEvents/components/constants";

type Props = {
  localizedWeekdays: LocalizedWeekday[];
  focusedWeekday: WeekdayNumber;
  viewingTimeZone: IANAtzName;
};

export const TimeLine = ({
  localizedWeekdays,
  focusedWeekday,
  viewingTimeZone,
}: Props) => {
  const [currentViewerDateTime, setCurrentViewerDateTime] = useState<Date>(
    utcToZonedTime(new Date(), viewingTimeZone)
  );

  // Establish a tick-rate using an interval.
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentViewerDateTime(utcToZonedTime(new Date(), viewingTimeZone));
    }, 5 * 1000);

    return () => clearInterval(interval);
  }, [viewingTimeZone]);

  const currentViewerDateInfo = getDateInfo(currentViewerDateTime);
  const startGridRow =
    Math.floor(
      currentViewerDateInfo.minutesElapsedInDay / GRID_RESOLUTION_MIN
    ) + 2;

  const inCurrentWeek = localizedWeekdays.some(
    (localizedWeekday) =>
      localizedWeekday.isoDate === currentViewerDateInfo.isoDate
  );

  return (
    <div
      className={clsx(
        "absolute w-full col-span-1 z-21",
        (!inCurrentWeek ||
          currentViewerDateInfo.weekdayNumber !== focusedWeekday) &&
          "hidden",
        WEEK_COL_START_CLASSES[currentViewerDateInfo.weekdayNumber]
      )}
      style={{ gridRow: `${startGridRow} / span 1` }}
    >
      <div className="relative flex items-center">
        <div className="bg-rose-400 w-full rounded-r-full h-1 lg:h-0.5" />
        <div className="absolute bg-rose-400 rounded-full h-3 w-3 -left-1.5 lg:h-2 lg:w-2 lg:-left-1" />
      </div>
    </div>
  );
};
