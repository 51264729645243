import { HolidayType } from "@generated/graphql";
import clsx from "clsx";
import { Icon, Tooltip } from "components/shared";
import { CalendarNavHolidayData } from "components/weekCalendar";
import { Fragment } from "react";

type Props = {
  navHolidays: CalendarNavHolidayData[];
};

export function HolidaysIndicator({ navHolidays }: Props) {
  return (
    <div className="flex flex-col gap-y-0.5 items-center w-full">
      {navHolidays.map((navHoliday) => (
        <Fragment key={navHoliday.id}>
          {renderHolidayBadge(navHoliday)}
        </Fragment>
      ))}
    </div>
  );
}

function renderHolidayBadge(navHoliday: CalendarNavHolidayData) {
  return (
    <Tooltip
      tooltipProps={{ place: "bottom" }}
      className={clsx(
        "flex flex-row flex-nowrap w-full px-1 py-0.5 items-center gap-x-1",
        "rounded-xs bg-green-600 cursor-help text-white text-xs font-medium"
      )}
      content={
        <div className="flex flex-col text-xs font-medium text-white text-center max-w-[36ch]">
          <p>
            {navHoliday.name}
            {navHoliday.daysCount > 1 &&
              ` (Day ${navHoliday.dayNumber} of ${navHoliday.daysCount})`}
          </p>
          <p className="italic">
            (
            {navHoliday.type === HolidayType.Usa
              ? "US Holiday"
              : `${
                  navHoliday?.organizationName ?? "Organization"
                } no tutoring day`}
            )
          </p>
        </div>
      }
    >
      <Icon icon="palmTree" color="text-white" size={4} />
      <p className="shrink truncate">{navHoliday.name}</p>
      {navHoliday.daysCount > 1 && (
        <p className="shrink-0">
          ({navHoliday.dayNumber}/{navHoliday.daysCount})
        </p>
      )}
    </Tooltip>
  );
}
