import { Cohort } from "@generated/graphql";
import { Modal } from "components/shared";
import pluralize from "pluralize";
import { ReactNode, useState } from "react";
import { CohortsScheduleCalendarModalBody } from "./components/CohortsScheduleCalendarModalBody";

type Props = {
  show: boolean;
  altTitle?: ReactNode;
  altSubtitle?: ReactNode;
  cohortIds: Cohort["id"][];
  closeModal: () => void;
  afterLeave?: () => void;
};

export const CohortsScheduleCalendarModal = ({
  show,
  altTitle,
  cohortIds,
  altSubtitle,
  afterLeave,
  closeModal,
}: Props) => {
  const [subtitle, setSubtitle] = useState<ReactNode>(altSubtitle);

  return (
    <Modal
      show={show}
      width="full"
      modalIsTopSticky
      subtitle={subtitle}
      afterLeave={afterLeave}
      onClose={() => closeModal()}
      onDismissClick={() => closeModal()}
      icon={<Modal.Icon icon="calendar" />}
      title={altTitle ?? pluralize("Cohort Schedule", cohortIds.length)}
    >
      <CohortsScheduleCalendarModalBody
        cohortIds={cohortIds}
        setSubtitle={altSubtitle ? undefined : setSubtitle}
      />
    </Modal>
  );
};
